import axios from "axios"
import { getCountNotifications} from "./StaticGetters"

export const getNotifications=async()=>{
    let data= await getCountNotifications()
    let dict = {
        'pending_dist': data.pending_dist,
        'approved_dist': data.approved_dist,
        'transfer_dist': data.transfer_dist,
        'approved_plan': data.approved_plan,
        'pre_auth_plan': data.pre_auth_plan
    }
    sessionStorage.setItem('notifications', JSON.stringify(dict))
}