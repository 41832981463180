import { Button, Input, Table } from "antd";
import React, { useEffect, useState, useRef } from "react";
import dayJS from "dayjs";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "../../Components/requisicao/print";
import RelatorioPlan from "./planModel";

const PlanificationDetail = ({ dados }) => {

    const printRelatorio = useRef()

    const column = [
        {
            title: 'Rubrica',
            dataIndex: 'expense_type',
            key: 'id',
            render: (text, record) => (
                <p>{record.expense_type.codigo}</p>
            )
        },
        {
            title: 'Designação',
            dataIndex: 'expense_type',
            key: 'id',
            render: (text, record) => (
                <p>{record.expense_type.nome}</p>
            )
        },
        {
            title: 'Categoria',
            dataIndex: 'expense_type',
            key: 'id',
            render: (text, record) => (
                <p>{record.expense_type.categoria}</p>
            )
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'id',
            align: 'right',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        }
    ]

    const columnsHist = [
        {
            title: 'Usuário',
            dataIndex: 'user',
            render: (text, record) => `${record?.user?.first_user_name} ${record?.user?.last_user_name}`,
            key: 'user',
        },
        {
            title: 'Acção',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <p>{record.action} {record.message ? `--Mensagem: ${record.message}` : ''}</p>
            ),
        },
        {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 130,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
    ]

    const columnsDists = [
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Período',
            dataIndex: 'period',
            key: 'id',
            render: (text, record) => (
                <p>{record.period.name}</p>
            ),
        },
        {
            title: 'Valor',
            dataIndex: 'orc_value',
            key: 'id',
            align: 'right',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        }
    ]


    const totalPlan = () => {
        let total = 0;
        dados?.plan?.map((item) => {
            total += parseFloat(item.value)
        })
        return `MZN ${parseFloat(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    }

    return (
        <div className="w-full flex flex-col">
            <div className='grid grid-cols-2 gap-4 w-full mt-2 mb-2'>
                <div className='flex flex-col'>
                    <p className='label-see'>Unidade Organica</p>
                    <Input
                        className='input-see'
                        value={dados?.unit?.name}
                        disabled
                    />
                </div>
            </div>
            <p className="title-form">Planificações</p>
            <Table
                className='custom-table mt-3'
                size="small"
                columns={column}
                dataSource={dados?.plan}
                pagination={false}
                locale={{
                    emptyText: 'Sem Planificação'
                }}
                footer={() => (
                    <div className='flex flex-row justify-between gap-3 mt-2'>
                        <p className='text' style={{ margin: 0 }}>Total</p>
                        <p className="text" style={{ margin: 0 }}>{totalPlan()}</p>
                    </div>
                )}
            />
            <p className="title-form">Distribuições Associadas</p>
            <Table
                className='custom-table mt-2 mb-0'
                size="small"
                columns={columnsDists}
                dataSource={dados?.distribution}
                pagination={false}
                locale={{
                    emptyText: 'Sem histórico'
                }}
            />
            <p className="title-form">Histórico</p>
            <Table
                className='custom-table mt-2 mb-0'
                size="small"
                columns={columnsHist}
                dataSource={dados?.logs}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                locale={{
                    emptyText: 'Sem histórico'
                }}
            />
            <div className="w-full flex justify-end">
                <ReactToPrint
                    trigger={() =>
                        <Button
                            className='button-in'
                        >
                            Imprimir Relatório
                        </Button>
                    }
                    content={() => printRelatorio.current}
                    documentTitle={'Planification Ref.' + dados?.reference}
                />
            </div>
            <div className="hidden">
                <ComponentToPrint ref={printRelatorio}>
                    <RelatorioPlan dados={dados}/>
                </ComponentToPrint>
            </div>
        </div >
    )
}

export default PlanificationDetail;