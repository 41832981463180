import { Steps, Form, Select, Row, Col, Button, Spin, Input, Statistic, notification, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { getDistribution, getDistributions, getPeriodDistribution, getTypeDistribution, getUnits } from "../../API/StaticGetters";
import { FaEye } from "react-icons/fa";
import { MdCancel, MdDelete } from "react-icons/md";
import '../../Components/css/general.css'
import { Table, Divider } from "antd";
import axios from "axios";
import dayJS from "dayjs";
import DistributionDetail from "../../Components/distribuicao/detail";

const Distribuir = () => {
    const [querying, setQuerying] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const [data,setData]=useState([])
    const [form] = Form.useForm();
    const [unidades, setUnidades] = useState([])
    const [periodos, setPeriodos] = useState([])
    const [tipos, setTipos] = useState([])
    const [receitas, setReceitas] = useState([
    ])
    const [selectedType, setSelectedType] = useState({})
    const [unidade, setUnidade] = useState({})
    const [periodo, setPeriodo] = useState({})
    const [plano, setPlano] = useState([])
    const [distribution, setDistribution] = useState({})
    const [modalView, setModalView]=useState(false)
    const [modalCancel, setModalCancel]=useState(false)
    const [selectedRow, setSelectedRow]=useState({})

    useEffect(() => {
        localStorage.setItem('title', 'Registrar Distribuição')
        localStorage.setItem('type', '2')
        getData()
    }, [])

    const getData = async () => {
        setLoading(true)
        try {
            let dists= await getDistributions(1)
            let dados = await getUnits()
            let dados2 = await getPeriodDistribution()
            let dados3 = await getTypeDistribution()
            setData(dists)
            setUnidades(dados)
            setPeriodos(dados2)
            setTipos(dados3)
        } finally {
            setLoading(false)
        }
    }

    const findData = async (values) => {
        setLoading(true)
        await axios.get(
            localStorage.getItem('url') + '/api/distribution/find/',
            {
                params: {
                    unidade_id: form.getFieldValue('unidade'),
                    periodo_id: form.getFieldValue('periodo')
                },
                headers: {
                    'Authorization': `Token ${sessionStorage.getItem('token')}`
                }
            }).then(res => {
                setReceitas(
                    Object.keys(res.data.receitas.valor).map(key => ({
                        tipo_receita: key,
                        valor: res.data.receitas.valor[key]
                    }))
                )
                setUnidade(res.data.unidade)
                setPeriodo(res.data.periodo)
                setCurrentStep(1)
            }
            ).catch(err => {
                notification.error({
                    message: err.response.data.message
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    //table
    const columns = [
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            width: 150,
        },
        {
            title: 'Unidade Organica',
            dataIndex: 'unidade',
            key: 'unidade',
            render: (text, record) => (
                <p>{record.unit.name}</p>
            ),
        },
        {
            title: 'Período',
            dataIndex: 'periodo',
            key: 'periodo',
            width: 200,
            render: (text, record) => (
                <p>{record.period.name}</p>
            ),
        },
        {
            title: 'Data de Registo',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
        {
            title: 'Acções',
            key: 'action',
            width: 60,
            render: (text, record) => (
                <span className='flex justify-center items-center'>
                    <a onClick={()=>handleView(record.id)}>
                        <FaEye
                            size={17}
                            color="#047d4b"
                        />
                    </a>
                    <Divider type='vertical' />
                    <a onClick={() => {handlCancel(record)}}>
                        <MdCancel
                            size={17}
                            color="#d6661b"
                        />
                    </a>
                </span>
            ),
        },
    ];

    const columnsReceipts = [
        {
            title: 'Receita',
            dataIndex: 'tipo_receita',
            key: 'tipo_receita',
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            key: 'valor',
        },
    ]

    const columnsPlano = [
        {
            title: 'Tipo de Distribuição',
            dataIndex: 'tipo',
            key: 'tipo',
        },
        {
            title: 'Percentagem',
            dataIndex: 'percentagem',
            key: 'percentagem',
            render: (text, record) => (
                <p>{record.percentagem}%</p>
            ),
            width: 200,
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            width: 200,
        },
        {
            title: 'Acções',
            key: 'action',
            width: 60,
            render: (text, record, index) => (
                <span className='flex justify-center items-center'>
                    <a onClick={() => removePlan(index)}>
                        <MdDelete
                            size={17}
                            color="#d6661b"
                        />
                    </a>
                </span>
            ),
        },
    ]

    const addPlan = (values) => {
        setPlano([...plano,
        {
            tipo_id: selectedType.id,
            tipo: selectedType.name,
            percentagem: values.percentagem,
            valor: values.valor
        }
        ])
        setSelectedType({})
        form.setFieldsValue({ tipo: null, percentagem: null, valor: null })
    }

    const removePlan = (index) => {
        let plan = [...plano]
        plan.splice(index, 1)
        setPlano(plan)
    }

    const resetProcess = () => {
        setPlano([])
        form.resetFields()
        setReceitas([])
        setSelectedType({})
        setPlano([])
        setDistribution({})
        setCurrentStep(0)
        getData()
    }

    const confirmProcess = async () => {
        setQuerying(true)
        if (plano.length > 0 && totalPorDistribuir() < 1) {
            await axios.post(
                localStorage.getItem('url') + '/api/distribution/',
                {
                    unidade_id: unidade.id,
                    periodo_id: periodo.id,
                    receitas: receitas,
                    fdi_value: plano.filter((item) => item.tipo_id == 2)[0].valor || 0,
                    apo_value: plano.filter((item) => item.tipo_id == 3)[0].valor || 0,
                    orc_value: plano.filter((item) => item.tipo_id == 1)[0].valor || 0
                },
                {
                    headers: {
                        'Authorization': `Token ${sessionStorage.getItem('token')}`
                    },
                }).then(res => {
                    notification.success({
                        message: 'Registo Efectuado Com Sucesso!'
                    })
                    setDistribution(res.data)
                    resetProcess()
                }).catch(err => {
                    notification.error({
                        message: err.response.data.message
                    })
                }).finally(()=>{
                    setQuerying(false)
                })
        } else {
            notification.error({
                message: 'Por favor, Distribua todo valor para poder continuar!'
            })
        }
    }

    const cancelDistribution = async() => {
        setLoading(true)
        axios.put(
            localStorage.getItem('url') + '/api/distribution/manage/',
            {
                ids: [selectedRow.id],
                action: 'Cancelou Distribuição',
                status: 6,
                message: 'Distribuição cancelada'
            },
            {
                headers: {
                    'Authorization': 'Token ' + sessionStorage.getItem('token')
                }
            },
        ).then(() => {
            notification.success({
                message: 'Distribuição Cancelada',
                description: 'Distribuição cancelada com sucesso'
            });
            getData()
            setModalCancel(false)
        }).catch(() => {
            notification.error({
                message: 'Erro ao Cancelar Distribuições',
                description: 'Erro ao cancelar distribuições, tente novamente'
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleView =async(id)=>{
        let dados=await getDistribution(id)
        setDistribution(dados)
        setModalView(true)
    }

    const handlCancel = (record) => {
        setSelectedRow(record)
        setModalCancel(true)
    }

    //Statistics
    const totalArrecadado = () => {
        let total = 0
        receitas.forEach(receita => {
            total += receita.valor
        })
        return total
    }

    const totalDistribuido = () => {
        let total = 0
        plano.forEach(item => {
            total += item.valor
        })
        return total
    }

    const totalPorDistribuir = () => {
        return totalArrecadado() - totalDistribuido()
    }

    return (
        <Spin spinning={querying}>
            <div className='w-full flex flex-col px-5 py-4'>
                <Steps current={currentStep} className="mb-3">
                    <Steps.Step title="Selecione a Unidade" />
                    <Steps.Step title="Distribuição" />
                </Steps>
                {currentStep === 0 && (
                    <>
                        <p className="title-form">Nova Distribuição</p>
                        <Form form={form} layout="vertical" onFinish={findData}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label={<p className='label-input'>Unidade Organica</p>}
                                        labelCol={{ span: 24 }}
                                        name="unidade"
                                        className='input'
                                        rules={[{ required: true, message: 'Por favor selecione a unidade' }]}
                                    >
                                        <Select
                                            placeholder="Selecione a unidade"
                                            allowClear
                                            className='input'
                                        >
                                            {unidades.map((unidade) => (
                                                <Select.Option key={unidade.id} value={unidade.id}>
                                                    {unidade.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={<p className='label-input'>Período que pretende Distribuir</p>}
                                        labelCol={{ span: 24 }}
                                        name="periodo"
                                        className='input'
                                        rules={[{ required: true, message: 'Por favor selecione o Período' }]}
                                    >
                                        <Select
                                            placeholder="Selecione o Período"
                                            allowClear
                                            className='input'
                                        >
                                            {periodos.map((period) => (
                                                <Select.Option key={period.id} value={period.id}>
                                                    {period.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item style={{ textAlign: 'right' }}>
                                <Button htmlType="submit" loading={loading} className='button-in'>
                                    Prosseguir
                                </Button>
                            </Form.Item>
                        </Form>
                        <p className="title-form">Distribuições em Aprovação</p>
                        <Table
                            className='custom-table mt-2'
                            size="small"
                            columns={columns}
                            dataSource={data}
                            loading={loading}
                            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                            locale={{
                                emptyText: 'Sem distribuições em aprovação'
                            }}
                        />

                    </>
                )}
                {currentStep === 1 && (<>
                    <div className="flex flex row w-full gap-8">
                        <div style={{width:'40%'}} className="flex flex-col">
                            <p className="title-form">Distribuição</p>
                            <div className='grid grid-cols-2 gap-4 w-full mt-2 mb-4'>
                                <div className='flex flex-col'>
                                    <p className='label-see'>Unidade Organica</p>
                                    <Input
                                        className='input-see'
                                        value={unidade?.name}
                                        disabled
                                    />
                                </div>
                                <div className='flex flex-col'>
                                    <p className='label-see'>Período</p>
                                    <Input
                                        className='input-see'
                                        value={periodo?.name}
                                        disabled
                                    />
                                </div>
                            </div>
                            <p className="title-form">Receitas arrecadadas no período</p>
                            <Table
                                className='custom-table mt-3'
                                size="small"
                                columns={columnsReceipts}
                                dataSource={receitas}
                                loading={loading}
                                pagination={{ defaultPageSize: 4, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                                locale={{
                                    emptyText: 'Sem receitas arrecadadas no período'
                                }}
                            />
                        </div>
                        <div style={{width:'60%'}} className="flex flex-col">
                            <p className="title-form">Alocar Distribuição</p>
                            <div className='grid grid-cols-3 gap-3 mt-3 mb-2'>
                                <Statistic
                                    title="Total Arrecadado"
                                    prefix="MZN"
                                    value={totalArrecadado()}
                                    precision={2}
                                    valueStyle={{ color: '#3f8600' }}
                                    className="border border-gray-300 p-2 rounded-lg"
                                />
                                <Statistic
                                    title="Total Distribuído"
                                    prefix="MZN"
                                    value={totalDistribuido()}
                                    precision={2}
                                    valueStyle={{ color: '#3f8600' }}
                                    className="border border-gray-300 p-2 rounded-lg"
                                />
                                <Statistic
                                    title="Por Distribuir"
                                    prefix="MZN"
                                    value={totalPorDistribuir()}
                                    precision={2}
                                    valueStyle={{ color: '#3f8600' }}
                                    className="border border-gray-300 p-2 rounded-lg"
                                />
                            </div>
                            <Form form={form} layout="vertical" onFinish={addPlan}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<p className='label-input'>Tipo de Distribuição</p>}
                                            labelCol={{ span: 24 }}
                                            name="tipo"
                                            className='input'
                                            rules={[{ required: true, message: 'Por favor selecione o tipo de distribuição' },
                                            {
                                                validator: (_, value) => {
                                                    if (plano.find(item => item.tipo_id === value)) {
                                                        return Promise.reject('Este tipo de distribuição já foi adicionado ao plano')
                                                    }
                                                    return Promise.resolve()
                                                }
                                            }]}
                                        >
                                            <Select
                                                placeholder="Selecione o tipo de distribuição"
                                                allowClear
                                                className='input'
                                                onChange={(value) => {
                                                    setSelectedType(tipos.find(tipo => tipo.id === value))
                                                }}
                                            >
                                                {tipos.map((tipo) => (
                                                    <Select.Option key={tipo.id} value={tipo.id}>
                                                        {tipo.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Percentagem</p>}
                                            labelCol={{ span: 24 }}
                                            name="percentagem"
                                            className='input'
                                            rules={[{ required: true, message: 'Por favor insira a percentagem' },
                                            {
                                                validator: (_, value) => {
                                                    if (value > selectedType?.max || value < selectedType?.min) {
                                                        return Promise.reject(`A percentagem deve estar entre ${selectedType?.min}% e ${selectedType?.max}%`)
                                                    }
                                                    return Promise.resolve()
                                                }
                                            }
                                            ]}
                                        >
                                            <Input
                                                prefix="%"
                                                placeholder="Percentagem"
                                                className='input-form'
                                                style={{ borderRadius: '0px' }}
                                                type="number"
                                                onChange={(e) => {
                                                    let valor = e.target.value
                                                    let total = 0
                                                    if (valor) {
                                                        total = (valor * totalArrecadado()) / 100
                                                    }
                                                    form.setFieldsValue({ valor: total })
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Valor</p>}
                                            labelCol={{ span: 24 }}
                                            name="valor"
                                            className='input'
                                            rules={[{ required: true, message: 'Por favor insira o valor' }]}
                                        >
                                            <Input
                                                prefix="MZN"
                                                placeholder="Valor"
                                                className='input-form'
                                                style={{ borderRadius: '0px' }}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item style={{ textAlign: 'right' }}>
                                    <Button htmlType="submit" loading={loading} className='button-in'>
                                        Alocar
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <p className="title-form">Plano de Distribuição</p>
                    <Table
                        className='custom-table mt-2'
                        size="small"
                        columns={columnsPlano}
                        dataSource={plano}
                        loading={loading}
                        pagination={false}
                        locale={{
                            emptyText: 'Sem plano de distribuição'
                        }}
                    />
                    <div className="flex flex-row justify-between mt-4">
                        <Button
                            onClick={resetProcess}
                            className='button-out'
                        >
                            Cancelar
                        </Button>
                        {plano.length > 0 &&
                            <Button
                                onClick={() => confirmProcess()}
                                className='button-in'
                            >
                                Confirmar
                            </Button>
                        }
                    </div>
                </>)}
                <Modal
                    title={<p className='title'>DETALHES DA DISTRIBUIÇÃO</p>}
                    open={modalView}
                    onOk={() => setModalView(false)}
                    onCancel={() => setModalView(false)}
                    footer={null}
                    width={700}
                >
                    <DistributionDetail dados={distribution}/>
                </Modal>
                <Modal
                    title={<p className='title'>Cancelar Distribuição</p>}
                    open={modalCancel}
                    onOk={() => setModalCancel(false)}
                    onCancel={() => setModalCancel(false)}
                    footer={null}
                    width={600}
                >
                    <div className="flex flex-col gap-3">
                        <p className="text">Tem certeza que deseja cancelar a distribuição?</p>
                        <div className="flex flex-row justify-end gap-3 ">
                            <Button
                                className="button-out"
                                onClick={() => setModalCancel(false)}
                            >
                                Não
                            </Button>
                            <Button
                                className="button-in"
                                onClick={cancelDistribution}
                            >
                                Sim
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default Distribuir;