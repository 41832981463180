import { Table } from "antd";
import React, { useEffect, useState, useRef } from "react";
import dayJS from "dayjs";

const RelatorioPlan = ({dados}) => {

    useEffect(() => {
    }, [])

    const column = [
        {
            title:'Rubrica',
            dataIndex:'expense_type',
            key:'id',
            render:(text,record)=>(
                <p>{record.expense_type.codigo}</p>
            )
        },
        {
            title:'Designação',
            dataIndex:'expense_type',
            key:'id',
            render:(text,record)=>(
                <p>{record.expense_type.nome}</p>
            )
        },
        {
            title:'Categoria',
            dataIndex:'expense_type',
            key:'id',
            render:(text,record)=>(
                <p>{record.expense_type.categoria}</p>
            )
        },
        {
            title:'Valor',
            dataIndex:'value',
            key:'id',
            align:'right',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        }
    ]

    const totalPlan = () => {
        let total = 0;
        dados?.plan.map((item) => {
            total += parseFloat(item.value)
        })
        return `MZN ${parseFloat(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    }

    const transformDate = (date) => {
        const meses = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];

        const data = dayJS(date);
        const dia = data.get("date");
        const mes = data.get("month");
        const ano = data.get("year");

        return `${dia} de ${meses[mes]} de ${ano}`;

    }

    return (
        <div className="px-8 py-5 flex flex-col">
            <div className="flex justify-center items-center w-full">
                <img
                    src={require("../../assets/logo/logo-v2.png")}
                    alt="logo"
                    className="w-24 mb-4"
                />

            </div>
            <p className="title text-center">{dados?.unit?.name}</p>
            <p className="title" style={{marginTop:15}}>1. Relatório de Planificação</p>
            <Table
                className='custom-table mt-3'
                size="small"
                columns={column}
                dataSource={dados?.plan}
                pagination={false}
                locale={{
                    emptyText: 'Sem Planificação'
                }}
                footer={() => (
                    <div className='flex flex-row justify-between gap-3 mt-2'>
                        <p className='text' style={{ margin: 0 }}>Total</p>
                        <p className="text" style={{ margin: 0 }}>{totalPlan()}</p>
                    </div>
                )}
            />

            <p className="text text-center mt-6">{transformDate(dados?.created_at)}</p>
        </div >
    )
}

export default RelatorioPlan;