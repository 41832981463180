import React, { useState, useEffect } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, User } from '@ant-design/icons';
import { Card } from 'antd';
import { QuestionCircleOutlined, MailOutlined, PhoneOutlined, PlusCircleFilled } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import { Link } from 'react-router-dom';
import { FaFileContract, FaFileSignature, FaHistory, FaMoneyBill, FaMoneyCheck, FaUserAlt} from 'react-icons/fa';
import { MdEditDocument } from 'react-icons/md';
import { getNotifications } from '../API/getNotification';

const Menu = (props) => {

  const [notifications, setNotifications] = useState({
    pending_dist: 0,
    approved_dist: 0,
    transfer_dist: 0,
    approved_plan: 0,
    pre_auth_plan: 0
  })

  useEffect(() => {
    localStorage.setItem('title', 'Bem-vindo a Plataforma');
    localStorage.setItem('type', '1');
    MyNotifications()
  }, [])

  const MyNotifications = async () => {
    await getNotifications()
    setNotifications({
      pending_dist: JSON.parse(sessionStorage.getItem('notifications')).pending_dist,
      approved_dist: JSON.parse(sessionStorage.getItem('notifications')).approved_dist,
      transfer_dist: JSON.parse(sessionStorage.getItem('notifications')).transfer_dist,
      approved_plan: JSON.parse(sessionStorage.getItem('notifications')).approved_plan,
      pre_auth_plan: JSON.parse(sessionStorage.getItem('notifications')).pre_auth_plan
    })
  }

  const links = [
    {
      title: 'Registrar Distribuição',
      icon: <MdEditDocument style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/nova-distribuicao',
      group: 5
    },
    {
      title: 'Aprovar Distribuição',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/aprovar-distribuicao',
      group: 3,
      type: 'pending-dist'
    },
    {
      title: 'Autorizar Distribuição',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/autorizar-distribuicao',
      group: 2,
      type: 'approved-dist'
    },
    {
      title: 'Transferir Distribuição',
      icon: <FaMoneyBill style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/transferir-distribuicao',
      group: 6,
      type: 'transfer-dist'
    },
    {
      title: 'Aprovar Planificação',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/aprovar-planificacao',
      group: 4,
      type: 'approved-plan'
    },
    {
      title: 'Autorizar Planificação',
      icon: <FaFileSignature style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/autorizar-planificacao',
      group: 2,
      type: 'pre-auth-plan'
    },
    {
      title: 'Histórico',
      icon: <FaHistory style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/historico',
      group: 'All'
    },
    {
      title: 'Meu Perfil',
      icon: <FaUserAlt style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: '/meu-perfil',
      group: 'All'
    }
  ]

  const getCurrentNotification = (type) => {
    switch (type) {
      case 'pending-dist':
        return notifications.pending_dist
      case 'approved-dist':
        return notifications.approved_dist
      case 'transfer-dist':
        return notifications.transfer_dist
      case 'approved-plan':
        return notifications.approved_plan
      case 'pre-auth-plan':
        return notifications.pre_auth_plan
      default:
        return 0
    }
  }

  const filteredLinks = links.filter(link => link.group === 'All' || link.group === parseInt(sessionStorage.getItem('access')))


  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-4  gap-4 p-2 sm:p-8">
        {filteredLinks.map((link, index) => (
          <Link to={link.link} key={index}>
            <Card
              bordered={false}
              hoverable
              cover={link.icon}
              className="relative"
            >
              <div className="text-sm sm:text-lg text-center">{link.title}</div>
              <p className={`absolute flex justify-center items-center top-0 right-7 w-4 h-4 bg-orange-500 text-white rounded-full ${link.type ? 'visible' : 'hidden'}`}>
                  {getCurrentNotification(link.type)}
                </p>
            </Card>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Menu;