import { Button, Input, Table } from "antd";
import { parse } from "date-fns";
import React, { useRef } from "react";
import dayJS from "dayjs";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "../requisicao/print";
import ModeloRequisicao from "../requisicao/modelo";

const ReqDetail = ({ dados }) => {
    const docRef = useRef()

    const columnsReceipts = [
        {
            title: 'Receita',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            key: 'value',
            width: 170,
            align: 'right'
        },
    ]

    const columnsHist = [
        {
            title: 'Usuário',
            dataIndex: 'user',
            render: (text, record) => `${record?.user?.first_user_name} ${record?.user?.last_user_name}`,
            key: 'user',
        },
        {
            title: 'Acção',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <p>{record.action} {record.message ? `--Mensagem: ${record.message}` : ''}</p>
            ),
        },
        {
            title: 'Data',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 130,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
    ]

    const totalArrecadado = () => {
        let list = dados?.revenue
        let total = 0
        list.forEach(receita => {
            total += parseFloat(receita.value)
        })
        return total
    }

    return (<>
        <div className="w-full flex flex-col">
            <div className='grid grid-cols-2 gap-4 w-full mt-2 mb-4'>
                <div className='flex flex-col'>
                    <p className='label-see'>Unidade Organica</p>
                    <Input
                        className='input-see'
                        value={dados?.distribution?.unit?.name}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Período</p>
                    <Input
                        className='input-see'
                        value={dados?.distribution?.period?.name}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Banco a Debitar</p>
                    <Input
                        className='input-see'
                        value={dados?.bankToDebit}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Banco a Creditar</p>
                    <Input
                        className='input-see'
                        value={dados?.bankToCredit}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Conta a Debitar</p>
                    <Input
                        className='input-see'
                        value={dados?.accountToDebit}
                        disabled
                    />
                </div>
                <div className='flex flex-col'>
                    <p className='label-see'>Conta a Creditar</p>
                    <Input
                        className='input-see'
                        value={dados?.accountToCredit}
                        disabled
                    />
                </div>
            </div>
            <ReactToPrint
                trigger={() =>
                    <Button
                        className='button-in'
                    >
                        Imprimir Requisição
                    </Button>
                }
                content={() => docRef.current}
                documentTitle={'Requisicao de Transferencia ' + dados?.distribution?.reference}
            />
            <div className="hidden">
                <ComponentToPrint ref={docRef}>
                    <ModeloRequisicao data={dados} />
                </ComponentToPrint>
            </div>
        </div>
    </>)
}

export default ReqDetail;