import React, { useEffect } from 'react';
import './modelo.css';
import numeroPorExtenso from 'numero-por-extenso';
import { data } from 'autoprefixer';
import dayjs from 'dayjs';
import Barcode from 'react-barcode';

const ModeloRequisicao = ({ data, bill }) => {


    const getDataAtual = () => {
        if (data?.updated_at) {
            const meses = [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ];

            const data2 = dayjs(data?.updated_at).toDate();
            const dia = data2.getDate();
            const mes = meses[data2.getMonth()];
            const ano = data2.getFullYear();

            return `${dia} de ${mes} de ${ano}`;
        } else {
            return '';
        }
    }

    const formatarMoeda = (valor) => {
        return parseFloat(valor).toLocaleString("pt-PT", {
            style: "currency",
            currency: "MZN",
        });
    }

    const valorPorExtenso = (valor) => {
        return numeroPorExtenso.porExtenso(valor);
    }

    return (
        <div class="requisicao">
            <div class="left">

            </div>
            <div class="right">
                <div class="nav">
                    <p class="titulo1"></p>
                </div>
                <div class="nav-destiny">
                    <p class="txtND">Ao</p>
                    <p class="txtND">{(data.bankToDebit).toUpperCase()}</p>
                    <p class="txtNT">Maputo</p>
                </div>
                <div class="nav-info">
                    <p class="txtNI" style={{fontWeight:'bold'}}>N/ Refª______/DFIN/2024</p>
                    <p class="txtNI">Maputo, { getDataAtual() }</p>
                </div>
                <div class="body">
                    <p class="txtBD" ><b className='txtBD'>Assunto:</b> Transferência conta a conta</p>
                    <p class="txtBD">Relativamente ao assunto em epigrafe, solicitamos a V. Excas. a efectivação da seguinte
                        operação:</p>
                    <div class="container">
                        <div class="container-left">
                            <ol>
                                <li class="itemBD">Operação</li>
                                <li class="itemBD">Valor</li>
                                <li class="itemBD">Beneficiário</li>
                                <li class="itemBD">Conta a Debitar </li>
                                <p class="itemBD2">Titular da Conta</p>
                                <li class="itemBD">Conta a Creditar</li>
                                <p class="itemBD2">Titular da Conta</p>
                                <li class="itemBD">Banco Credor</li>
                                <li class="itemBD">Referências</li>
                            </ol>
                        </div>
                        <div class="container-right">
                            <ul>
                                <li class="itemBD">Transferência Bancária</li>
                                <li class="itemBD" style={{fontWeight:'bold'}}>#{ formatarMoeda(data.value) }</li>
                                <li class="itemBD">UEM-{ data.distribution?.unit?.name }</li>
                                <li class="itemBD">{data.accountToDebit}</li>
                                <p class="itemBD2">UEM-DFIN Fundo de Propinas</p>
                                <li class="itemBD">{ data.accountToCredit }</li>
                                <p class="itemBD2">UEM-{ data.distribution?.unit?.name }</p>
                                <li class="itemBD">{ data.bankToCredit }</li>
                                <li class="itemBD">Compensações financeiras</li>
                            </ul>
                        </div>
                    </div>
                    <p class="txtBD">Valor por extenso: { valorPorExtenso(data.value) } meticais</p>
                </div>
                <div class="assinatura">
                    <p class="txtBD">A Direcção de Finanças</p>
                </div>
            </div>
        </div>
    )
}

export default ModeloRequisicao;