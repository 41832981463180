import { Steps, Form, Select, Row, Col, Button, Spin, Input, Statistic, notification, Modal, Result } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { getDistribution, getDistributions, getFundRequest, getPeriodDistribution, getRetentions, getTypeDistribution, getUnits } from "../../API/StaticGetters";
import { FaDoorOpen, FaEdit, FaEye, FaFileSignature } from "react-icons/fa";
import { MdDelete, MdSettings } from "react-icons/md";
import '../../Components/css/general.css'
import { Table, Divider } from "antd";
import axios from "axios";
import dayJS from "dayjs";
import DistributionDetail from "../../Components/distribuicao/detail";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "../../Components/requisicao/print";
import ModeloRequisicao from "../../Components/requisicao/modelo";

const Transferir = () => {
    const docRef = useRef()
    const [querying, setQuerying] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(0)
    const [data, setData] = useState([])
    const [form] = Form.useForm();
    const [distribution, setDistribution] = useState({})
    const [process, setProcess] = useState()
    const [retentions, setRetentions] = useState([])
    const [modalView, setModalView] = useState(false)
    const [modalDist, setModalDist] = useState(false)


    useEffect(() => {
        localStorage.setItem('title', 'Transferir Distribuições');
        localStorage.setItem('type', '2')
        getData()
    }, [])

    const getData = async () => {
        setLoading(true)
        try {
            let dados = await getFundRequest(1)
            setData(dados)
        } finally {
            setLoading(false)
        }
    }

    const createDist = async () => {
        let ids = []
        let type = modalDist === 'fdi' ? 'FDI' : 'APO'
        let total = 0
        retentions.forEach(retention => {
            total += parseFloat(retention.value)
        })
        let valor = total

        retentions.forEach(retention => {
            ids.push(retention.id)
        })

        await axios.post(
            localStorage.getItem('url') + '/api/distribution/manage/',
            {
                ids: ids,
                type: type,
                value: valor
            },
            {
                headers: {
                    'Authorization': 'Token ' + sessionStorage.getItem('token')
                }
            },
        ).then(() => {
            notification.success({
                message: 'Requisição de Distribuição',
                description: 'Gerado com sucesso'
            });
            getData()
        }).catch(() => {
            notification.error({
                message: 'Erro ao gerar a requisição de distribuição',
                description: 'Por favor tente novamente'
            });
        }).finally(() => {
            setModalDist(false)
            setLoading(false)
            resetProcess()
        })


    }

    //table
    const columns = [
        {
            title: 'Referência',
            dataIndex: 'reference',
            key: 'reference',
            width: 150,
        },
        {
            title: 'Unidade Organica',
            dataIndex: 'unidade',
            key: 'unidade',
            render: (text, record) => (
                <p>{record?.distribution?.unit?.name}</p>
            ),
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            width: 200,
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        },
        {
            title: 'Data de Requisição',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
        },
        {
            title: 'Acções',
            key: 'action',
            width: 60,
            render: (text, record) => (
                <span className='flex justify-center items-center'>
                    <a onClick={() => handleView(record?.distribution?.id)}>
                        <FaEye
                            size={17}
                            color="#047d4b"
                        />
                    </a>
                    <Divider type='vertical' />
                    <a onClick={() => handleProcess(record)}>
                        <FaFileSignature
                            size={17}
                            color="#047d4b"
                        />
                    </a>
                </span>
            ),
        },
    ];

    const columnsRetentions = [
        {
            title: 'Unidade Organica',
            dataIndex: 'unit',
            key: 'unit',
            render: (text, record) => (
                <p>{record?.distribution?.unit?.name}</p>
            ),
        },
        {
            title: 'Período',
            dataIndex: 'period',
            key: 'period',
            render: (text, record) => (
                <p>{record?.distribution?.period?.name}</p>
            ),
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
            width: 150,
            render: (text) => `MZN ${parseFloat(text).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
            align: 'right'
        },
    ]

    const handleView = async (id) => {
        let dados = await getDistribution(id)
        setDistribution(dados)
        setModalView(true)
    }

    const handleProcess = (record) => {
        setProcess(record)
        form.setFieldsValue({
            bankToCredit: record?.distribution?.unit?.bankToCredit,
            accountToCredit: record?.distribution?.unit?.accountToCredit,
            unit: record?.distribution?.unit?.name,
            value: `MZN ${parseFloat(record?.value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        })
        setCurrentStep(1)
    }

    const submitProcess = async () => {
        setLoading(true)
        await axios.put(
            localStorage.getItem('url') + '/api/distribution/fundrequest/',
            {
                'id': process?.id,
                'distribution': process?.distribution?.id,
                'bankToDebit': form.getFieldValue('bankToDebit'),
                'accountToDebit': form.getFieldValue('accountToDebit'),
                'bankToCredit': form.getFieldValue('bankToCredit'),
                'accountToCredit': form.getFieldValue('accountToCredit'),
                'status': 7
            },
            {
                headers: {
                    'Authorization': `Token ${sessionStorage.getItem('token')}`
                },
            }
        ).then(res => {
            notification.success({
                message: 'Distribuição Transferida com Sucesso'
            })
            setProcess(res.data)
            setCurrentStep(currentStep + 1)
        }).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const resetProcess = async () => {
        await getData()
        form.resetFields()
        setCurrentStep(0)
    }

    const resetRetentions = () => {
        setRetentions([])
        setModalDist(false)
    }

    const generateDist = async (type) => {
        if (type === 'fdi') {
            let dados = await getRetentions(2, 1)
            setRetentions(dados)
            setModalDist(type)
        } else if (type === 'apo') {
            let dados = await getRetentions(3, 1)
            setRetentions(dados)
            setModalDist(type)
        }
    }

    const totalRetencao = () => {
        let total = 0
        retentions.forEach(retention => {
            total += parseFloat(retention.value)
        })
        return `MZN ${parseFloat(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    }

    return (
        <Spin spinning={querying}>
            <div className='w-full flex flex-col px-5 py-4'>
                <Steps current={currentStep} className="mb-3">
                    <Steps.Step title="Pedidos" />
                    <Steps.Step title="Requisição" />
                    <Steps.Step title="Conclusão" />
                </Steps>
                {currentStep === 0 && (
                    <>
                        <p className="title-form">Requisições de Distribuição</p>
                        <div className="flex flex-row justify-end gap-3">
                            <Button className="button-in" onClick={() => generateDist('fdi')}>
                                Gerar Distribuição de FDI
                            </Button>
                            <Button className="button-in" onClick={() => generateDist('apo')}>
                                Gerar Distribuição de APO
                            </Button>
                        </div>
                        <Table
                            className='custom-table mt-2'
                            size="small"
                            columns={columns}
                            dataSource={data}
                            loading={loading}
                            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '15', '50', '100'] }}
                            locale={{
                                emptyText: 'Sem requisições de distribuição'
                            }}
                        />
                    </>
                )}
                {currentStep === 1 && (<>
                    <div className="w-full flex flex-row">
                        <div className="w-full flex flex-col gap-3">
                            <p className="title-form">Detalhes da Requisição</p>
                            <div className='notification-status-static'>
                                <p className='notification-text-static'>
                                    Caro Tesoureiro, antes de confirmar o processo, por favor verifique os campos abaixo.
                                </p>
                            </div>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={submitProcess}
                            >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Unidade Organica</p>}
                                            labelCol={{ span: 24 }}
                                            className='input'
                                            name='unit'
                                            rules={[{ required: true, message: 'Por favor insira a unidade organica' }]}
                                        >
                                            <Input
                                                placeholder="Unidade Organica"
                                                className='input-form'
                                                style={{ borderRadius: '0px' }}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Valor a tranferir</p>}
                                            labelCol={{ span: 24 }}
                                            className='input'
                                            name='value'
                                            rules={[{ required: true, message: 'Por favor insira o valor a transferir' }]}
                                        >
                                            <Input
                                                placeholder="Valor"
                                                className='input-form'
                                                style={{ borderRadius: '0px' }}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Banco a Creditar</p>}
                                            labelCol={{ span: 24 }}
                                            className='input'
                                            name='bankToCredit'
                                            rules={[{ required: true, message: 'Por favor insira o banco a creditar' }]}
                                        >
                                            <Input
                                                placeholder="Banco a Creditar"
                                                className='input-form'
                                                style={{ borderRadius: '0px' }}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Conta a Creditar</p>}
                                            labelCol={{ span: 24 }}
                                            className='input'
                                            name='accountToCredit'
                                            rules={[{ required: true, message: 'Por favor insira a conta a creditar' }]}
                                        >
                                            <Input
                                                placeholder="Conta a Creditar"
                                                className='input-form'
                                                style={{ borderRadius: '0px' }}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Banco a Debitar</p>}
                                            labelCol={{ span: 24 }}
                                            className='input'
                                            name='bankToDebit'
                                            rules={[{ required: true, message: 'Por favor insira o banco a debitar' }]}
                                        >
                                            <Input
                                                placeholder="Banco a Debitar"
                                                className='input-form'
                                                style={{ borderRadius: '0px' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<p className='label-input'>Conta a Debitar</p>}
                                            labelCol={{ span: 24 }}
                                            className='input'
                                            name='accountToDebit'
                                            rules={[{ required: true, message: 'Por favor insira a conta a debitar' }]}
                                        >
                                            <Input
                                                placeholder="Conta a Debitar"
                                                className='input-form'
                                                style={{ borderRadius: '0px' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className="flex flex-row justify-end gap-3 mt-2">
                                    <Button
                                        className="button-out"
                                        onClick={() => setCurrentStep(0)}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        className="button-in"
                                        loading={loading}
                                        htmlType="submit"
                                    >
                                        Confirmar
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </>)}
                {currentStep === 2 && (<>
                    <Result
                        className='p-0 mt-4'
                        status="success"
                        title={<p className='title'>Requisição Emitida</p>}
                        subTitle={<p className='text'>A requisição foi emitida com sucesso</p>}
                        extra={[
                            <div className="flex flex-row justify-center gap-3 mt-2">
                                <Button className='button-out' onClick={resetProcess}>
                                    Terminar
                                </Button>
                                <ReactToPrint
                                    trigger={() =>
                                        <Button
                                            className='button-in'
                                        >
                                            Imprimir Requisição
                                        </Button>
                                    }
                                    content={() => docRef.current}
                                    documentTitle={'Requisicao de Transferencia ' + process?.distribution?.reference}
                                />
                            </div>
                        ]}
                    />

                    <div className="hidden">
                        <ComponentToPrint ref={docRef}>
                            <ModeloRequisicao data={process} />
                        </ComponentToPrint>
                    </div>
                </>)}
                <Modal
                    title={<p className='title'>DETALHES DA DISTRIBUIÇÃO</p>}
                    open={modalView}
                    onOk={() => setModalView(false)}
                    onCancel={() => setModalView(false)}
                    footer={null}
                    width={700}
                >
                    <DistributionDetail dados={distribution} />
                </Modal>

                <Modal
                    title={<p className='title'>NOVA DISTRIBUIÇÃO {modalDist === 'fdi' ? 'FDI' : 'APO'}</p>}
                    open={modalDist}
                    onOk={() => setModalDist(false)}
                    onCancel={() => setModalDist(false)}
                    footer={null}
                    width={600}
                >
                    <p className='text'>Retenções disponíveis para distribuição</p>
                    <Table
                        className='custom-table mt-2'
                        size="small"
                        columns={columnsRetentions}
                        dataSource={retentions}
                        loading={loading}
                        locale={{
                            emptyText: 'Sem Retenções disponíveis'
                        }}
                        pagination={false}
                        footer={() => (
                            <div className='flex flex-row justify-between gap-3 mt-2'>
                                <p className='text' style={{ margin: 0 }}>Total</p>
                                <p className="text" style={{ margin: 0 }}>{totalRetencao()}</p>
                            </div>
                        )}
                    />
                    <div className="flex flex-row justify-end gap-3 mt-2">
                        <Button
                            className="button-out"
                            onClick={() => resetRetentions()}
                        >
                            Cancelar
                        </Button>
                        <Button
                            className="button-in"
                            onClick={() => createDist()}
                            loading={loading}
                        >
                            Confirmar
                        </Button>
                    </div>
                </Modal>
            </div>
        </Spin>
    )
}

export default Transferir;